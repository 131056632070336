 /* Estilos generales */
body {
   margin: 0;
   font-family: 'Arial', sans-serif;
   background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

/* Estilo para el contenedor de login */
.conlogin {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100vh;
   text-align: center;
}

/* Estilo para el título */
.conlogin h1 {
   font-size: 2.5rem;
   color: #333;
   margin-bottom: 2rem;
}

/* Estilo para el botón */
.conlogin button {
   padding: 10px 20px;
   font-size: 1rem;
   color: #fff;
   background-color: #007bff;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s ease;
}

/* Estilo para el botón al pasar el mouse */
.conlogin button:hover {
   background-color: #0056b3;
}

 nav a {
  text-decoration: none;
  padding-right: 20px;
  color: white;
  font-size: 1em;
 }
 
 nav {
  background-color: darkslategrey;
 }
 
 /* Additional CSS for Enhancement */
 nav {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
 }
 
 nav a:hover {
  color: #ddd;
 }
 
 nav a.active {
  color: #ff6347; /* Tomato color for active link */
 }
 
 /* Estilo para el botón */
.unlog button {
   padding: 6px 12px;
   font-size: 1rem;
   color: #fff;
   background-color: #007bff;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s ease;
}

/* Estilo para el botón al pasar el mouse */
.unlog button:hover {
   background-color: #0056b3;
}
 
 






/* Estilo para el contenedor de recover */
.recover {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100vh;
   text-align: center;
}

/* Estilo para el título */
.recover h1 {
   font-size: 2.5rem;
   color: #333;
   margin-bottom: 2rem;
}

/* Estilo para el botón */
.recover button {
   padding: 10px 20px;
   font-size: 1rem;
   color: #fff;
   background-color: #007bff;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s ease;
}

/* Estilo para el botón al pasar el mouse */
.recover button:hover {
   background-color: #0056b3;
}